.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}

.blinkSignatureDate {
  animation: 2s ease-in fadeIn 1;
}


@keyframes fadeIn {
  0% {
    opacity: 30;
    color: #ff9c29;
    visibility: hidden;
  }
  100% {
    color: #686868;
    opacity: 1;
    visibility: visible;
  }
}
